import { defineStore } from "pinia";
import { useSharedStore } from "@/entrypoints/stores/shared";
import { usePresentationStore } from "@/entrypoints/stores/presentation";
import { useVotingModulesStore } from "@/entrypoints/stores/voting_modules";
import crypto from "@/entrypoints/shared/aion_crypto";
import apiClient from "@/entrypoints/frontend/apiClient";
import { VoterChannelSubscription, voterChannelSubscription } from "@/channels/VoterChannel";
import { reactive, ref } from "vue";
import { VotingVoter, VotingE2E, VotingEncryptionConfig } from "@/types";

export const useVotingSessionStore = defineStore("votingSession", () => {
  const sharedStore = useSharedStore();
  const presentationStore = usePresentationStore();
  const votingModulesStore = useVotingModulesStore();

  // State
  const voting = ref<boolean>(false);
  const privateKey = ref<string>(null);
  const publicKey = ref<string>(null);
  const signature = ref<string>(null);
  const voter = reactive<VotingVoter>({
    id: null,
    identifier: null,
    weight: 0,
    votedOn: [],
    canVoteOn: [],
    name: "",
    voterGroupReference: null,
    demo: false,
    participating: null,
  });
  const electionCodes = ref<string[]>(null);
  const voterSessionUuid = ref<string>(null);
  const redoVote = ref<boolean>(false);
  const expireReason = ref<string>(null);
  const encryptionConfig = reactive<VotingEncryptionConfig>({
    type: "unencrypted",
    publicKey: null,
  });
  const e2e = reactive<VotingE2E>({
    contests: [],
  });

  // Actions
  const setVoting = (payload: boolean) => voting.value = payload;

  const setRedoVote = (payload: boolean) => {
    redoVote.value = payload;
  }

  const updateVoter = (newVoter: VotingVoter) => {
    voter.id = newVoter.id;
    voter.identifier = newVoter.identifier;
    voter.name = newVoter.name;
    voter.weight = newVoter.weight;
    voter.canVoteOn = newVoter.canVoteOn;
    voter.demo = newVoter.demo;
    voter.voterGroupReference = newVoter.voterGroupReference;
    voter.participating = newVoter.participating;
  }

  const authenticate = async (eCodes: string[]) => {
    privateKey.value = eCodes.map((code: string) =>
      crypto.electionCodeToPrivateKey(code, "pbkdf2")).reduce(crypto.addBigNums);
    publicKey.value = crypto.generateKeyPair(privateKey.value).public_key;
    signature.value = crypto.generateSchnorrSignature("", privateKey.value);
    electionCodes.value = eCodes;

    try {
      const response = await apiClient.post(`${sharedStore.electionUrl}/authenticate`, {
        public_key: publicKey.value,
        signature: signature.value,
      });

      const data = await response.data;

      if (!!data.voter_session_uuid) {
        voterSessionUuid.value = data.voter_session_uuid;
        encryptionConfig.publicKey = data.encryptionConfig.publicKey;
        updateVoter(data.voter);
      }
    } catch (error) {
      if (error.response) {
        return error.response.data.error;
      }
    }
  }

  const subscribeToVoterChannel = () => {
    voterChannelSubscription.value = new VoterChannelSubscription(voterSessionUuid.value, {
      received: function(data: any) {
        switch (data.type) {
          case "update_voter":
            updateVoter(data.voter);
            break;
          case "session_expired":
            data.expire_reason 
              ? destroySession(data.expire_reason)
              : destroySession();
            break;
        }
      },
    });
  }

  const destroySession = (reason: string = null) => {
    presentationStore.setVisibleTab("Presentation");
    voterChannelSubscription.value?.unsubscribe();
    resetState();
    expireReason.value = reason;
    votingModulesStore.setPosts([]);
    presentationStore.setSlides([]);
  }

  const resetState = () => {
    privateKey.value = null;
    publicKey.value = null;
    signature.value = null;
    voter.id = null;
    voter.identifier = null;
    voter.weight = 0;
    voter.votedOn = [];
    voter.canVoteOn = [];
    voter.name = "";
    voter.voterGroupReference = null;
    voter.demo = false;
    voter.participating = null;
    electionCodes.value = null;
    voterSessionUuid.value = null;
    redoVote.value = false;
    encryptionConfig.type = "unencrypted";
    encryptionConfig.publicKey = null;
    e2e.contests = [];
  }

  const resetExpireReason = () => expireReason.value = null;

  const prolongSession = async () => {
    await apiClient.put(`${sharedStore.electionUrl}/prolong`, {
      signature: signature.value,
      voter_session_uuid: voterSessionUuid.value,
    });
  }

  const fetchVotedOn = async () => {
    const response = await apiClient.get(`${sharedStore.election.boardUrl}/statistics/votes/voted_on?voter_identifier=${voter.identifier}`);
    if (response.status === 200) {
      response.data.voting_round_references.forEach((votingRoundReference: string) => {
        setVoted(votingRoundReference);
      });
    }
  }

  const reportVoted = async (ballotId: number) => {
    let config = {
      ballotId: ballotId,
      signature: signature.value,
      voter_session_uuid: voterSessionUuid.value,
    };

    return await apiClient.post(`${sharedStore.electionUrl}/report_voted`, config);
  }

  const setVoted = (votingRoundReference: string) => {
    if (voter.votedOn.includes(votingRoundReference)) return;
    voter.votedOn.push(votingRoundReference);
  }

  return {
    voting,
    setVoting,
    voter,
    updateVoter,
    signature,
    voterSessionUuid,
    setRedoVote,
    destroySession,
    subscribeToVoterChannel,
    prolongSession,
    expireReason,
    resetExpireReason,
    authenticate,
    electionCodes,
    fetchVotedOn,
    reportVoted,
    setVoted,
  }
}, {
  persist: {
    storage: sessionStorage,
    paths: ["voting", "privateKey", "publicKey", "signature", "voter", "voterSessionUuid", "electionCodes", "e2e", "encryptionConfig", "expireReason", "redoVote"],
  },
});
