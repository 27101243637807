import consumer from "./consumer";
import { UniqueChecker } from "@/lib/UniqueChecker";
import { Subscription } from "@rails/actioncable";
import { ref } from "vue";
import { Message } from "@/types";

class ElectionChannelSubscription {
  public subscription: Subscription;
  private uniqueChecker: UniqueChecker<string>;

  constructor({ electionId }, { received, connected = undefined, disconnected = undefined }: {
  received: ((data: Message) => void);
  connected?: () => void;
  disconnected?: () => void;
}) {
    this.uniqueChecker = new UniqueChecker<string>();
    this.subscription = consumer.subscriptions.create(
      {
        channel: "ElectionChannel",
        election_id: electionId,
      },
      {
        received: (data: Message) => {
          if (this.uniqueChecker.wasSeen(data.uuid))
            return console.debug("DUPLICATE MESSAGE RECEIVED: ", data);
          received(data);
        },
        connected() {
          console.debug("ElectionChannel connected")
        },
        disconnected() {
          console.debug("ElectionChannel disconnected")
        },
      }
    );
  }

  unsubscribe() {
    this.subscription.unsubscribe();
  }
};

const electionChannelSubscription = ref(null);

export { ElectionChannelSubscription, electionChannelSubscription }
